<template>
  <v-row>
      <v-col cols="12">
          <base-card>
              <v-card-title>
                  <div class="d-flex justify-space-between flex-wrap">
                      <v-btn class="ma-2" dark color="danger">
                          <v-icon>mdi-plus</v-icon>
                          Add Investment
                      </v-btn>
                  </div>
              </v-card-title>
              <v-card-title>
                  Investments
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>

                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
              </v-card-title>
              <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="items"
                  item-key="name"
                  show-select
                  class="elevation-1 table-one"
                  multi-sort
              >
                  <template v-slot:item.name="{item}">
                      <div class="d-flex align-center">
                          <p class="ma-0 font-weight-medium">
                              {{ item.name }}
                          </p>
                      </div>
                  </template>
                  <template v-slot:item.action="{item}">
                      <div class="d-flex">
                          <v-tooltip top>
                              <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                      color="success"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                  >
                                      <v-icon>mdi-pencil-box-outline</v-icon>
                                  </v-btn>
                              </template>
                              <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                              <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                      color="danger"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                  >
                                      <v-icon>mdi-trash-can-outline</v-icon>
                                  </v-btn>
                              </template>
                              <span>Delete</span>
                          </v-tooltip>
                      </div>
                  </template>
              </v-data-table>
          </base-card>
      </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Investments'
  },
  data() {
      return {
          search: '',
          headers: [
              {
                  text: 'Name',
                  align: 'start',
                  sortable: true,
                  value: 'name'
              },
              {text: 'Description', value: 'description', sortable: false},
              {text: 'Url', value: 'url', sortable: false},
              {text: 'Currency Code', value: 'currencyCode', sortable: false},
              {text: 'Action', value: 'action', sortable: false}
          ],
          items: [
              {
                  name: 'VaporNodes',
                  description: 'VaporNodes',
                  url: 'https://app.vapornodes.finance/nodes',
                  currencyCode: 'VPND'
              },
              {
                  name: 'YieldNodes',
                  description: 'YieldNodes',
                  url: 'https://members.yieldnodes.com/en/',
                  currencyCode: 'EURO'
              },
              {
                  name: 'Drip',
                  description: 'Drip Community',
                  url: 'https://drip.community',
                  currencyCode: 'DRIP'
              },
              {
                  name: 'StableFund',
                  description: 'StableFund',
                  url: 'https://dashboard.stablefund.app',
                  currencyCode: 'SFUSD'
              },
              {
                  name: 'Optimus',
                  description: 'Optimus',
                  url: 'https://optimus.money',
                  currencyCode: 'OPT3'
              },
              {
                  name: 'Prosperity Gem Ventures',
                  description: 'Prosperity Gem Ventures',
                  url: 'https://prosperitygemventures.io',
                  currencyCode: 'USDT'
              },
              {
                  name: 'MayoTrade',
                  description: 'MayoTrade',
                  url: 'https://maytrade.com',
                  currencyCode: 'USDT'
              }                
            ]
      }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
      tr {
          &:hover {
              background-color: #f2f3f8;
          }
          th {
              span {
                  font-size: 16px;
                  color: #304156;
              }
          }
      }
      tr {
          td {
              padding-bottom: 20px;
              padding-top: 20px;
          }
      }
  }
  tbody {
      tr {
          &:hover {
              background-color: #f2f3f8 !important;
          }
      }
  }
}
</style>
